<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        {{ addCourseForm.page_name }}
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col cols="12">
            <b-form-group label="العنوان" label-for="blog-content" class="mb-2">
              <b-form-input id="title form" v-model="addCourseForm.title" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="الوصف" rules="">
                
                <QuillEditor
                  :editorRef="'descriptionEditor'"
                   v-model="addCourseForm.description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="">
            <div class="border rounded p-2" style="margin: 0px">
              <h4 class="mb-1">header صورة</h4>
              <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="`https://lpcentre.com/${addCourseForm.image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE2.src = Tabimage; addCourseForm.image=''"
                  >
                    ازالة
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group label-for="blog-edit-title" class="mb-2">
                      <validation-provider #default="{ errors }" name="header Image ">
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCourseForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="Image Caption" label-for="blog-edit-title" class="mb-2">
                      <b-form-input id="alt-image" v-model="addCourseForm.image_caption" />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="4">
            <b-form-group label=" Meta Keywords" label-for="blog-content" class="mb-2">
              <b-form-tags v-model="addCourseForm.meta_keywords" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label=" Meta Title" label-for="blog-edit-title" class="mb-2">
              <validation-provider #default="{ errors }" name="Meta Title" rules="required">
                <b-form-input id="blog-edit-title" v-model="addCourseForm.meta_title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Meta Description" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="Meta Description" rules="required">
                <b-form-input id="blog-edit-slug" v-model="addCourseForm.meta_description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox, 
  BLink,

  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Editor from '@tinymce/tinymce-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import vSelect from "vue-select";
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import QuillEditor from "@core/components/editor/Editor";
export default {
  setup() {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('media/svg/files/blank-image.svg')

    const form = ref({
      description: '',
      title: '',
      meta_title: '',
      meta_description: '',
      meta_keywords: '',
      keywords: '',
      image: '',
      image_caption: '',
      video_link: '',
      category_id: '',
    })
    const addCourseForm = reactive(form)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      refPreviewEl.value.src = base64
    })
    const { inputImageRendererTab } = useInputImageRenderer(refInputE2, base64 => {
      refPreviewE2.value.src = base64
    })
    const addCourseFormvalidate = ref()

    const editorConfig = {
      toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image code media template link anchor codesample | ltr rtl',
      toolbar_sticky: true,
      images_upload_url: 'https://api.lpcentre.com/api/upload_image_tiny_mce',
      images_upload_handler: example_image_upload_handler,
      cleanup: false,
      verify_html: false,
      autosave_ask_before_unload: true,
      autosave_interval: '30s',
      autosave_prefix: '{path}{query}-{id}-',
      autosave_restore_when_empty: false,
      autosave_retention: '2m',
      plugins: 'autoresize insertdatetime media table wordcount preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons'
    }

    const save = () => {
      const formData = new FormData()
      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          console.log(addCourseForm)
           if (addCourseForm.value.title && addCourseForm.value.title !== 'null') {
            formData.append('title', addCourseForm.value.title)
          }
     
          if (addCourseForm.value.description && addCourseForm.value.description !== 'null') {
            formData.append('description', addCourseForm.value.description)
          }
         if (addCourseForm.value.meta_title && addCourseForm.value.meta_title !== 'null') {
            formData.append('meta_title', addCourseForm.value.meta_title)
          }
            if (addCourseForm.value.page_name && addCourseForm.value.page_name !== 'null') {
            formData.append('page_name', addCourseForm.value.page_name)
          }
            if (addCourseForm.value.meta_description && addCourseForm.value.meta_description !== 'null') {
            formData.append('meta_description', addCourseForm.value.meta_description)
          }
           if (addCourseForm.value.image && addCourseForm.value.image !== 'null') {
            formData.append('image', addCourseForm.value.image)
          }
           if (addCourseForm.value.image_caption && addCourseForm.value.image_caption !== 'null') {
            formData.append('image_caption', addCourseForm.value.image_caption)
          }
           if (addCourseForm.value.meta_keywords && addCourseForm.value.meta_keywords !== 'null') {
            formData.append('meta_keywords', addCourseForm.value.meta_keywords)
          }
           if (addCourseForm.value.title && addCourseForm.value.title !== 'null') {
            formData.append('title', addCourseForm.value.title)
          }
      
          formData.append('_method', 'put')
          store
            .dispatch('seo/updatePage', { id, formData })
            .then(response => {
              Vue.swal({
                title: ' تم الحفظ ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const { route } = useRouter()
    const { id } = route.value.params
    store.dispatch('seo/getPage', { id }).then(response => {
      form.value = response.data
      form.value.keywords = response.data.keywords.split(',')
    })

    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr
      let formData
      const token = localStorage.getItem('token')

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', 'https://api.lpcentre.com/api/upload_image_tiny_mce')

      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }

    return {
      example_image_upload_handler,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      form,
      save,
      editorConfig,
    }
  },
  components: {
    BCard,
    BMedia,
    Editor,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BFormTags,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    QuillEditor,
  },
  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
